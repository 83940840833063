import Vue from 'vue';
import Swiper, { FreeMode } from 'swiper';

Swiper.use([FreeMode]);

Vue.component('SliderComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            swiperSlider: null,
            slidesCount: 1,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.init();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        init() {
            this.initSwiper();
        },

        initSwiper() {
            this.swiperSlider = new Swiper(this.$refs.slider, {
                modules: [FreeMode],
                direction: 'horizontal',
                loop: false,
                slidesPerView: 2.25,
                centeredSlides: false,
                spaceBetween: 10,
                speed: 600,
                freeMode: {
                    enabled: false,
                    sticky: false,
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2.75,
                        spaceBetween: 5,
                    },
                    1024: {
                        slidesPerView: 3.5,
                        spaceBetween: 5,
                    },
                },
            });
        },

        nextSlide() {
            const current = this.swiperSlider.activeIndex;
            this.swiperSlider.slideTo(current + 1);
            document
            .querySelectorAll('#inline-slider .module__slider-prev')[0]
            .classList.remove('swiper-button-disabled');
            if (this.swiperSlider.isEnd) {
                document
                .querySelectorAll('#inline-slider .module__slider-next')[0]
                .classList.add('swiper-button-disabled');
            }
        },

        previousSlide() {
            const current = this.swiperSlider.activeIndex;
            this.swiperSlider.slideTo(current - 1);
            document
            .querySelectorAll('#inline-slider .module__slider-next')[0]
            .classList.remove('swiper-button-disabled');
            if (this.swiperSlider.isBeginning) {
                document
                .querySelectorAll('#inline-slider .module__slider-prev')[0]
                .classList.add('swiper-button-disabled');
            }
        },

        destroySwiper() {
            if (!this.swiperSlider) {
                return;
            }

            this.swiperSlider.destroy();
            this.swiperSlider = null;
        },
    },
});
