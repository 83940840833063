// import Plyr from 'plyr/dist/plyr.polyfilled';
import Vue from 'vue';

Vue.component('VideoComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            video: null,
            videoPlaying: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        // Vue.nextTick(() => {
        //     Vue.nextTick(this.setupVideo);
        // });
        this.intersect();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        intersect() {
            const options = {
                rootMargin: '300px',
                threshold: 0,
            };

            const { video } = this.$refs;

            // eslint-disable-next-line no-shadow
            const observer = new IntersectionObserver((entries, observer) => {
                const isIntersecting = typeof entries[0].isIntersecting === 'boolean'
                    ? entries[0].isIntersecting
                    : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    this.setupVideo();
                    observer.unobserve(video);
                }
            }, options);

            observer.observe(video);
        },

        playVideo() {
            this.video.play();
            this.videoPlaying = true;
        },

        async setupVideo() {
            const Plyr = await import(/* webpackChunkName: "plyr" */ 'plyr')
            .then((module) => module.default) // using the default export
            .catch(() => {
                console.log('Handle error!');
            });

            this.video = new Plyr(this.$refs.video, {
                controls: [
                    'play-large',
                    'play',
                    'progress',
                    'current-time',
                    'mute',
                    'volume',
                    'fullscreen',
                ],
                youtube: {
                    noCookie: true,
                },
            });

            if (!this.video) {
                return;
            }

            this.video.on('play', (event) => {
                this.videoPlaying = true;
            });

            this.video.on('pause', (event) => {
                this.videoPlaying = false;
            });

            this.video.on('seeking', (event) => {
                this.videoPlaying = true;
            });

            this.video.on('ended', (event) => {
                this.videoPlaying = false;
            });
        },
    },
});
