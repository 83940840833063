import Vue from 'vue';
import { gsap } from 'gsap';

Vue.component('InfoSection', {
    data() {
        return {
            activeSection: 0,
        };
    },

    mounted() {
    },

    methods: {
        setActiveSection(event, index) {
            this.activeSection = index;

            if (!event.currentTarget) return;

            if (window.matchMedia('(max-width: 1023px)').matches) {
                const answer = event.currentTarget.nextSibling;

                if (event.currentTarget.classList.contains('active')) {
                    const tl = gsap.timeline();

                    tl.to(answer, {
                        height: 0,
                        paddingBottom: 0,
                        duration: 0.3,
                        onComplete: event.currentTarget.classList.remove('active'),
                    }, '<');

                    return;
                }

                const tl = gsap.timeline();

                tl.to(answer, {
                    height: 'auto',
                    paddingBottom: '40px',
                    duration: 0.3,
                    onComplete: event.currentTarget.classList.add('active'),
                }, '<');
            }
        },
    },
});
