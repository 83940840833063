/* global google */

import Vue from 'vue';

Vue.component('LocationGoogleMapComponent', {
    props: {
        mapCenterLat: Number,
        mapCenterLong: Number,
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            map: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.init();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        init() {
            EventBus.$once('google-maps-ready', this.initMap);

            this.intersect();
        },

        /**
         * Initialize the Google Map
         *
         * @return {Void}
         */
        initMap() {
            const mapStyle = [
                {
                    featureType: 'all',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'all',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#c1cdd2',
                        },
                        {
                            lightness: 17,
                        },
                    ],
                },
                {
                    featureType: 'landscape.man_made',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#f2f2f2',
                        },
                    ],
                },
                {
                    featureType: 'landscape.natural',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#f2f2f2',
                        },
                    ],
                },
                {
                    featureType: 'administrative',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#f2f2f2',
                        },
                        {
                            lightness: 17,
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [
                        {
                            color: '#FFFFFF',
                        },
                        {
                            lightness: 17,
                        },
                    ],
                },

            ];

            this.map = new google.maps.Map(document.getElementById('gmap'), {
                zoom: 17,
                center: { lat: this.mapCenterLat, lng: this.mapCenterLong },
                styles: mapStyle,
                scrollwheel: false,
                // disableDefaultUI: true
                mapTypeControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM,
                },
                scaleControl: true,
                streetViewControl: true,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP,
                },
            });

            const latlng = new google.maps.LatLng(this.mapCenterLat, this.mapCenterLong);
            const marker = new google.maps.Marker({
                position: latlng,
                icon: '/media/images/map-pin.svg',
                map: this.map,
            });
        },

        intersect() {
            const options = {
                rootMargin: '300px',
                threshold: 0,
            };

            const map = document.getElementById('map-holder');

            const observerMap = new IntersectionObserver((entries, observer) => {
                const isIntersecting = typeof entries[0].isIntersecting === 'boolean'
                    ? entries[0].isIntersecting
                    : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    this.injectGoogleMaps();
                    observer.unobserve(map);
                }
            }, options);

            observerMap.observe(map);
        },

        /**
         * Injects the Google Maps scripts when it is not there yet.
         *
         * @return {Void}
         */
        injectGoogleMaps() {
            const tag = document.createElement('script');
            tag.setAttribute('type', 'text/javascript');
            tag.defer = true;
            tag.async = true;
            tag.setAttribute(
                'src',
                '//maps.google.com/maps/api/js?key=AIzaSyB7-Ss2hy1jFbQO1Sb5ADDAsX5O4AR0hJM&callback=mapsCallback',
            );
            (
                document.getElementsByTagName('head')[0]
                || document.documentElement
            ).appendChild(tag);
        },
    },
});
