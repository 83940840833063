<template>
  <a
    :href="item.url"
    class="vacancy-card"
  >
    <div>
      <h3 class="text-vacancy-title">{{ item.title }}</h3>
      <div class="vacancy-card__icons">
        <IconLabel
          :value="item.locations.join(', ')"
          :icon="'map-pin'"
        />
        <IconLabel
          :value="item.employmentTypes.join(', ')"
          :icon="'clock'"
        />
        <IconLabel
          :value="item.educationLevels.join(', ')"
          :icon="'education'"
        />
        <IconLabel
          v-if="item.salary"
          :value="item.salary"
          :icon="'salary'"
        />
      </div>
    </div>
    <div class="vacancy-card__bottom text-body-small">
      {{ item.summary }}
    </div>
  </a>
</template>

<script>
import IconLabel from '../icon-label.vue';

export default {
    name: 'VacancyCard',
    components: { IconLabel },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>
