import Vue from 'vue';
import Dropzone from 'dropzone';
import Axios from 'axios';

Vue.component('cv-upload', {
    data() {
        return {
            dropzone: null,
            file: null,
            assetObject: null,
            error: null,
            success: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.dropzone = new Dropzone('.dropzone', {
                url: '/actions/site-module/upload/store/',
                maxFiles: 1,
                uploadMultiple: false,
                createImageThumbnails: false,
                dictDefaultMessage: 'Klik hier of sleep een bestand naar dit vlak om te uploaden',
                dictInvalidFileType: 'Dit bestandstype wordt niet ondersteund, gebruik .pdf, .doc of .docx',
                acceptedFiles: '.pdf,.docx,.doc',
            });

            this.dropzone.on('sending', (file, xhr, formData) => {
                formData.append(window.csrfTokenName, window.csrfTokenValue);
            });

            this.dropzone.on('complete', (file) => {
                const parsedResponse = JSON.parse(file.xhr.response);

                if (!parsedResponse.data) {
                    return;
                }

                this.file = file;
                this.assetObject = parsedResponse.data;

                console.log(this.assetObject);
            });
        },

        submit() {
            console.log(this.assetObject?.id);
            console.log(window.csrfTokenValue);

            if (!this.assetObject?.id) {
                this.error = 'Geen bestand geüpload';
                return;
            }

            Axios.post('/actions/site-module/upload/index/', {
                fileId: this.assetObject?.id,
            }, {
                headers: {
                    'X-CSRF-TOKEN': window.csrfTokenValue,
                },
            }).then((response) => {
                console.log('response', response);
                this.success = true;
            }).catch((error) => {
                console.log('error', error);
            });
        },
    },
});
