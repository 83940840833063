<template>
    <form @submit.prevent="submitForm">
        <input type="text" />
        <input type="text" />
        <button type="submit">Submit</button>
    </form>
</template>

<script>
import axios from 'axios';

export default {
    name: 'VacancyForm',
    props: {
        vacancyId: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.getForm();
    },
    methods: {
        getForm() {
            console.log(this.vacancyId);
            axios
                .get(
                    `https://opusuitzendburo.recruitnowcockpit.nl/jobsite/api/applicationforms/${this.vacancyId}`,
                    { params: { formId: '' } }
                )
                .then((response) => console.log(response))
                .catch((error) => console.log(error.response));
        },
        submitForm() {
            axios
                .post(
                    'https://opusuitzendburo.recruitnowcockpit.nl/jobsite/api/applications',
                    {
                        application: {
                            vacancyId,
                            applicationFormId: '',
                            tracking: {},
                            responses: [],
                        },
                    }
                )
                .then((response) => console.log(response))
                .catch((error) => console.log(error));
        },
    },
};
</script>
