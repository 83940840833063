<template>
    <div class="icon-label">
        <svg
            v-if="icon === 'map-pin'"
            class="icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="#3F3F3F"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1219_4927)">
                <path
                    d="M17.5 8.33594C17.5 14.1693 10 19.1693 10 19.1693C10 19.1693 2.5 14.1693 2.5 8.33594C2.5 6.34681 3.29018 4.43916 4.6967 3.03264C6.10322 1.62611 8.01088 0.835937 10 0.835938C11.9891 0.835938 13.8968 1.62611 15.3033 3.03264C16.7098 4.43916 17.5 6.34681 17.5 8.33594Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10 10.8359C11.3807 10.8359 12.5 9.71665 12.5 8.33594C12.5 6.95523 11.3807 5.83594 10 5.83594C8.61929 5.83594 7.5 6.95523 7.5 8.33594C7.5 9.71665 8.61929 10.8359 10 10.8359Z"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1219_4927">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <svg
            v-else-if="icon === 'clock'"
            class="icon"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1219_4922)">
                <path
                    d="M10.8704 18.3307C15.4728 18.3307 19.2038 14.5998 19.2038 9.9974C19.2038 5.39502 15.4728 1.66406 10.8704 1.66406C6.26807 1.66406 2.53711 5.39502 2.53711 9.9974C2.53711 14.5998 6.26807 18.3307 10.8704 18.3307Z"
                    stroke="#3F3F3E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.8706 5V10L14.2039 11.6667"
                    stroke="#3F3F3E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1219_4922">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.870605)"
                    />
                </clipPath>
            </defs>
        </svg>
        <svg
            v-else-if="icon === 'education'"
            class="icon"
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1219_4932)">
                <path
                    d="M8.80012 0.640004C9.46143 0.226694 10.2231 0.0078125 11.0001 0.0078125C11.7771 0.0078125 12.5388 0.226694 13.2001 0.640004L21.6858 6.15956C21.7949 6.22192 21.883 6.31586 21.939 6.42933C21.995 6.5428 22.0163 6.67063 22.0001 6.79643C22.0163 6.92223 21.995 7.05005 21.939 7.16352C21.883 7.27699 21.7949 7.37093 21.6858 7.4333L18.3335 9.66235V16.0311H18.2287C18.2287 16.1372 18.2287 16.1372 18.1239 16.2434L17.8096 16.5618L16.5525 17.5171C14.8723 18.5216 12.9507 19.0359 11.0001 19.0031C9.08498 19.0164 7.20218 18.5028 5.5525 17.5171L4.29536 16.5618L3.98108 16.2434C3.87631 16.1372 3.87631 16.1372 3.87631 16.0311C3.76806 15.9126 3.69537 15.7653 3.66679 15.6065V9.66235L1.57155 8.28246V13.9082C1.58945 14.0238 1.58013 14.1421 1.54434 14.2534C1.50855 14.3647 1.44732 14.4659 1.36562 14.5487C1.28392 14.6314 1.18407 14.6935 1.07422 14.7298C0.964358 14.766 0.8476 14.7755 0.733456 14.7573C0.627668 14.7584 0.522897 14.7363 0.426335 14.6925C0.329773 14.6487 0.243705 14.5843 0.174039 14.5036C0.104374 14.423 0.0527595 14.328 0.0227393 14.2252C-0.00728089 14.1224 -0.0149963 14.0143 0.00012271 13.9082V6.79643C-0.0150947 6.72663 -0.0162384 6.65443 -0.00323984 6.58417C0.00975873 6.51391 0.0366317 6.44704 0.0757666 6.38756C0.114902 6.32808 0.165492 6.27723 0.224502 6.23805C0.283512 6.19887 0.349726 6.17217 0.41917 6.15956L8.80012 0.640004ZM13.2001 13.059C12.5388 13.4723 11.7771 13.6912 11.0001 13.6912C10.2231 13.6912 9.46143 13.4723 8.80012 13.059L5.23822 10.7238V15.288H5.34298L6.3906 16.1372C7.79369 16.9557 9.38116 17.3944 11.0001 17.411C12.6098 17.4268 14.1967 17.0249 15.6096 16.2434L16.6573 15.3942H16.762V10.7238L13.2001 13.059ZM12.362 2.01989C11.956 1.75479 11.4832 1.61381 11.0001 1.61381C10.5171 1.61381 10.0443 1.75479 9.63822 2.01989L2.20012 6.79643L9.63822 11.6791C10.0443 11.9442 10.5171 12.0852 11.0001 12.0852C11.4832 12.0852 11.956 11.9442 12.362 11.6791L19.8001 6.79643L12.362 2.01989Z"
                    fill="#3F3F3E"
                />
            </g>
            <defs>
                <clipPath id="clip0_1219_4932">
                    <rect width="22" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <svg
            v-else
            class="icon"
            width="24"
            height="19"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 5.39844V17.3984H19"
                stroke="#3F3F3E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <rect
                x="5"
                y="1.39844"
                width="18"
                height="12"
                stroke="#3F3F3E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle cx="14" cy="7.39844" r="2" fill="#3F3F3E" />
        </svg>

        <span class="icon-label__label">{{ value }}</span>
    </div>
</template>

<script>
export default {
    name: 'IconLabel',
    props: {
        value: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
    },
};
</script>
