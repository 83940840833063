<template>
    <form @submit.prevent="checkForm">
        <ValidationProvider v-slot="v" rules="positive">
            <input v-model="name" type="text" />
            <span>{{ v.errors[0] }}</span>
        </ValidationProvider>
        <button type="submit">Verzend</button>
    </form>
</template>

<script>
import axios from 'axios';
import { ValidationProvider, extend } from 'vee-validate';

extend('positive', (value) => {
    return value >= 0;
});

export default {
    name: 'OpenRegistrationForm',
    components: {
        ValidationProvider,
    },
    data() {
        return {
            name: null,
            errors: [],
        };
    },
    methods: {
        checkForm() {
            this.errors = [];

            if (!this.name) {
                this.errors.push('Name required');
            }

            axios
                .post('/test', { name: this.name })
                .then((response) => console.log(response));
        },
    },
};
</script>
