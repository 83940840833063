<template>
    <div class="vacancy-list">
        <vacancy-search 
            :queries="queries" 
            @searchVacancies="onSearch"
        />
        <div class="vacancy-list__bottom">
            <div>
                <desktop-filters
                    :sectors="sectors"
                    :education-levels="educationLevels"
                    :employment-types="employmentTypes"
                    @changeFilters="onFiltersChange"
                    :activeFilters="activeFilters"
                    :regions="regions"
                    :translations="translations"
                />
            </div>
            <div class="vacancy-results__holder" id="vacancies">
                <h2 class="text-body-title">{{ vacancyCount }} Vacatures</h2>
                <TransitionGroup name="list" tag="div" class="vacancy-results" v-if="filteredVacancies.length">
                    <vacancy-card
                        v-for="vacancy in filteredVacancies"
                        :key="vacancy.id"
                        :item="vacancy"
                    />
                </TransitionGroup>
                <p v-else class="no-vacancies">Er zijn geen vacatures gevonden die aan uw zoekopdracht voldoen...</p>

                <div class="pagination" v-if="maxPages > 1">
                    <div class="pagination__arrow" :class="{ 'disabled' : currentIndex === 1 }">
                        <a @click.prevent="prevPage">
                            <svg class="" width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5137 16.4863L3.02735 9L10.5137 1.51372" stroke="black" stroke-width="3"/>
                            </svg>
                        </a>
                    </div>

                    <div class="pagination__numbers">
                        <a v-for="(page, index) in maxPages" @click.prevent="jumpToPage(index + 1)" :class="{ 'active' : currentIndex === (index + 1) }">{{ index + 1 }}</a>
                    </div>

                    <div class="pagination__numbers--mobile">
                        <strong>{{( currentIndex )}}</strong> / {{ maxPages }}
                    </div>

                    <div class="pagination__arrow" :class="{ 'disabled' : currentIndex === maxPages }">
                        <a @click.prevent="nextPage">
                            <svg class="" width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.48633 1.51372L8.97265 9L1.48633 16.4863" stroke="black" stroke-width="3"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="filter-bar"
            :class="{ 'filter-bar--active': filtersOpened }"
            @click.prevent="toggleFilters"
        >
            <span class="filter-bar__label">Filter</span>
            <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.01841 7.00132L6.00908 2.01025L10.9999 7.00112"
                    stroke="white"
                    stroke-width="2"
                />
            </svg>
        </div>

        <div class="mobile__filters-container" :class="{ '--active': filtersOpened }">
            <div class="mobile__filters mobile-padding">
                <mobile-filters
                    id="mobile-filters"
                    :sectors="sectors"
                    :education-levels="educationLevels"
                    :employment-types="employmentTypes"
                    @changeFilters="onFiltersChange"
                    :activeFilters="activeFilters"
                    :regions="regions"
                    :translations="translations"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import VacancyCard from './vacancy-card.vue';
import VacancyFilters from './vacancy-filters.vue';
import VacancySearch from './vacancy-search.vue';
import zenscroll from 'zenscroll';

export default {
    name: 'VacancyList',
    components: {
        VacancyCard,
        'desktopFilters' : VacancyFilters,
        'mobileFilters' : VacancyFilters,
        VacancySearch,
    },
    props: {
        initialVacancies: {
            type: Array,
            required: true,
        },
        sectors: {
            type: Array,
            required: true,
        },
        educationLevels: {
            type: Array,
            required: true,
        },
        employmentTypes: {
            type: Array,
            required: true,
        },
        regions: {
            type: Array,
            required: true,
        },
        queries: {
            type: Object,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        translations: {
            type: Object,
            required: false,
        },
    },

    data() {
        return {
            postcodeResults: [],
            vacancies: this.initialVacancies,
            searchTerms: {
                query: null,
                location: null,
                distance: null,
            },
            activeFilters: {
                sectors: [],
                employmentTypes: [],
                educationLevels: [],
                regions: [],
            },
            searchVacancies: this.initialVacancies,
            filtersOpened: false,
            currentIndex: 1,
            maxPages: 0,
            vacancyCount:this.initialVacancies.length
        };
    },

    computed: {
        filteredVacancies() {
            if (
                this.activeFilters.sectors.length === 0 &&
                this.activeFilters.employmentTypes.length === 0 &&
                this.activeFilters.educationLevels.length === 0 &&
                this.activeFilters.regions.length === 0 &&
                this.searchTerms.query === null &&
                this.searchTerms.location === null
            ) {
                this.vacancyCount = this.vacancies.length;
                this.getPagination(this.vacancies);

                return this.vacancies.slice(((this.currentIndex * this.itemsPerPage) - this.itemsPerPage), this.currentIndex * this.itemsPerPage);
            }

            const buildQuery = (filter) => {
                const query = {};
                Object.keys(filter).forEach((keys) => {
                    if (
                        filter[keys].constructor === Array &&
                        filter[keys].length > 0
                    ) {
                        query[keys] = filter[keys];
                    }
                });

                return query;
            };

            let results = [];

            const filterData = (data, query) => {
                const filteredData = data.filter((vacancy) => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const key in query) {
                        if (Object.prototype.hasOwnProperty.call(query, key)) {
                            let found = false;
                            if (vacancy[key] === undefined) {
                                return false;
                            }

                            const filters = vacancy[key];

                            filters.forEach((filter) => {
                                if (query[key].includes(filter)) {
                                    found = true;
                                }
                            });

                            if (!found) {
                                return false;
                            }
                        }
                    }

                    return true;
                });

                return filteredData;
            };

            const query = buildQuery(this.activeFilters);

            results = filterData(this.vacancies, query);

            if (this.searchTerms.query !== null) {
                if (results.length === 0) {
                    results = this.vacancies;
                }

                results = results.filter((vacancy) =>
                    vacancy.title
                        .toLowerCase()
                        .includes(this.searchTerms.query.toLowerCase())
                );
            };

            if (this.searchTerms.location) {
                if (this.postcodeResults.length) {
                    results = results.filter((item) => {
                        if (this.postcodeResults.includes(item.recruitnowId)) {
                            return true;
                        } 

                        return false;
                    });
                } else {
                    results = [];
                }
            }

            this.vacancyCount = results.length;
            this.getPagination(results);
            
            return results.slice(((this.currentIndex * this.itemsPerPage) - this.itemsPerPage), this.currentIndex * this.itemsPerPage);
        },
    },

    methods: {
        getPagination(items = this.filteredVacancies) {
            this.maxPages = Math.ceil(items.length / this.itemsPerPage);

            if (this.currentIndex > this.maxPages) {
                this.currentIndex = this.maxPages === 0 ? 1 : this.maxPages;
            }
        },

        scrollToTop() {
            zenscroll.toY(0);
        },

        prevPage() {
            this.currentIndex -= 1;
            this.scrollToTop();
        },

        jumpToPage(index) {
            this.currentIndex = index;
            this.scrollToTop();
        },

        nextPage() {
            this.currentIndex += 1;
            this.scrollToTop();
        },

        onSearch({ terms, results }) {
            if (!terms && !results) {
                Vue.set(this.searchTerms, 'query', null);
                Vue.set(this.searchTerms, 'distance', null);
                Vue.set(this.searchTerms, 'location', null);
                return;
            }

            Vue.set(this.searchTerms, 'query', terms.query);
            Vue.set(this.searchTerms, 'distance', terms.distance);
            Vue.set(this.searchTerms, 'location', terms.postcode);

            if (results) {
                this.postcodeResults = results;
            }
        },

        onFiltersChange({ sectors, employmentTypes, educationLevels, regions }) {
            if (!sectors.length && !employmentTypes.length && !educationLevels.length && !regions.length) {
                this.activeFilters = {
                    sectors: [],
                    employmentTypes: [],
                    educationLevels: [],
                    regions: []
                };

                return;
            }

            Vue.set(this.activeFilters, 'sectors', sectors);
            Vue.set(this.activeFilters, 'employmentTypes', employmentTypes);
            Vue.set(this.activeFilters, 'educationLevels', educationLevels);
            Vue.set(this.activeFilters, 'regions', regions);
        },

        toggleFilters() {
            // this.filtersOpened = !this.filtersOpened;

            if (this.filtersOpened === false) {
                this.filtersOpened = true;
                disableBodyScroll(document.querySelector('.mobile__filters-container'), {reserveScrollBarGap:true});
            } else {
                this.filtersOpened = false;
                enableBodyScroll(document.querySelector('.mobile__filters-container'));
            }
        },
    },

    mounted() {
        this.getPagination();
    }
};
</script>
