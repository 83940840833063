<template>
    <form @submit.prevent="submit">
        <div class="vacancy-search__holder">
            <input
                v-model="search.query"
                class="input vacancy-search__input vacancy-search__input--query"
                type="text"
                placeholder="Functie of trefwoord"
            />
            <div class="vacancy-search__location-wrapper">
                <input
                    v-model="search.postcode"
                    class="input vacancy-search__input vacancy-search__input--postcode"
                    type="text"
                    placeholder="Postcode"
                />
                <select
                    v-model="search.distance"
                    class="select vacancy-search__select"
                >
                    <option value="10">< 10km</option>
                    <option value="25">< 25km</option>
                    <option value="50">< 50km</option>
                </select>
            </div>
            <button
                type="submit"
                class="vacancy-search__button text-button-cta"
            >
                Zoeken
            </button>
        </div>
    </form>
</template>

<script>
export default {
    name: 'VacancySearch',
    props: {
        queries: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            search: {
                query: '',
                postcode: '',
                distance: '10',
            },
            results: []
        };
    },
    mounted() {
        this.search.query = this.queries.search;
        this.search.postcode = this.queries.postcode;

        if (this.queries.search !== '' || this.queries.postcode !== '') {
            this.submit();
        }
    },
    methods: {
        submit() {
            if (!this.search.query && !this.search.postcode) {
                this.$emit('searchVacancies', { terms: null });
                return;
            }

            if (this.search.query) {
                this.$emit('searchVacancies', { terms: this.search });
            }

            if (this.search.postcode && this.search.distance) {
                axios
                    .post(
                        '/actions/site-module/distance/index',
                        {
                            postcode: this.search.postcode,
                            distance: this.search.distance,
                        },
                        {
                            headers: {
                                'X-CSRF-Token': window.csrfTokenValue,
                            },
                        }
                    )
                    .then((response) => {
                        this.$emit('searchVacancies', { terms: this.search, results: response.data.vacancies });
                    });
            }
        },
    },
};
</script>
