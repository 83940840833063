import Vue from 'vue';

Vue.component('SearchFormFooter', {
    data() {
        return {
            query: '',
            errors: [],
        };
    },
    methods: {
        onSubmit() {
            this.errors = [];

            if (!this.query) {
                this.errors.push('Dit veld is verplicht');
                return;
            }

            const match = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(
                this.query,
            );

            if (match) {
                window.location = `/vacatures?postcode=${this.query}`;
            } else {
                window.location = `/vacatures?zoeken=${this.query}`;
            }
        },
    },
});
