import Vue from 'vue';
import { gsap } from 'gsap';

Vue.component('VerticalTickerComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            tickerContainer: null,
            tickerText: null,
            tickerHeight: null,
            tickerTimeline: null,
            velocity: 1,
            viewportWidth: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        EventBus.$on('window-resize', () => {
            const newViewportWidth = window.innerWidth || document.documentElement.clientWidth;

            if (newViewportWidth !== this.viewportWidth) {
                this.destroyTicker(true);
            }
        });

        // The delayed call is needed because the font is still loading when initializing this.
        gsap.delayedCall(0.4, this.initTicker.bind(this));

        if (window.matchMedia('(min-width: 768px)').matches) {
            EventBus.$on('horizontal-scroll-event', (speed) => {
                if (speed.speed > 0) {
                    this.velocity = 1;
                }

                if (speed.speed > 1) {
                    this.velocity = speed.speed;
                }

                if (speed.speed < 0) {
                    this.velocity = -1;
                }

                if (speed.speed < -1) {
                    this.velocity = speed.speed;
                }
            });
        }
    },

    beforeDestroy() {
        this.destroyTicker();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initTicker() {
            this.tickerContainer = this.$el.querySelector('.vertical-ticker__container');
            this.tickerText = this.$el.querySelectorAll('.vertical-ticker__text');
            this.tickerTimeline = gsap.timeline();

            if (window.matchMedia('(min-width: 768px)').matches) {
                this.tickerHeight = this.tickerText[0].getBoundingClientRect().height;

                this.tickerTimeline.to(this.tickerContainer, {
                    duration: 18,
                    ease: 'none',
                    y: this.tickerHeight, // move each box 500px to right
                    modifiers: {
                        y: gsap.utils.unitize(
                            (y) => parseFloat(y) % this.tickerHeight,
                        ), // force x value to be between 0 and 500 using modulus
                    },
                    repeat: -1,
                    overwrite: true,
                    onUpdate: () => {
                        this.tickerTimeline.timeScale(this.velocity);
                    },
                });
            } else {
                this.tickerWidth = this.tickerText[0].getBoundingClientRect().width;

                this.tickerTimeline.to(this.tickerContainer, {
                    duration: 12,
                    ease: 'none',
                    x: -this.tickerWidth, // move each box 500px to right
                    modifiers: {
                        x: gsap.utils.unitize(
                            (x) => parseFloat(x) % this.tickerWidth,
                        ), // force x value to be between 0 and 500 using modulus
                    },
                    repeat: -1,
                });
            }
        },

        destroyTicker(reinit = false) {
            if (!this.tickerTimeline) {
                return;
            }

            this.tickerTimeline.kill();
            this.velocity = 1;

            if (!reinit) {
                return;
            }

            if (window.matchMedia('(min-width: 768px)').matches) {
                gsap.set(this.tickerContainer, {
                    y: 0,
                });
            } else {
                gsap.set(this.tickerContainer, {
                    x: 0,
                });
            }

            Vue.nextTick(() => {
                this.initTicker();
            });
        },
    },
});
