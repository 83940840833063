import Vue from 'vue';

Vue.component('SpecialVideoComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {

        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        playVideo() {
            EventBus.$emit('play-overlay-video');
        },
    },
});
