import Vue from 'vue';
import { gsap } from 'gsap';

Vue.component('StickerRotationComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        gsap.to('.whatsapp-cta', {
            duration: 1,
            scale: 1,
            ease: 'power4.out',
            delay: 0.8,
            onComplete: () => {
                document.querySelector('.whatsapp-cta').classList.add('revealed');
                gsap.set('.whatsapp-cta', { clearProps: 'all' });
            },
        });

        gsap.fromTo('.whatsapp-cta__text', { rotation: 0 }, {
            duration: 20, rotation: 360, repeat: -1, ease: 'none',
        });
    },

    beforeDestroy() {
        this.destroyTicker();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
    },
});
