/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { gsap, ScrollTrigger } from 'gsap/all';
import Hammer from 'hammerjs';
import imagesLoaded from 'imagesloaded';

gsap.registerPlugin(ScrollTrigger);

Vue.component('ReferencesSliderComponent', {
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentIndex: 1,
            direction: 0,
            inTransition: false,
            slidesEl: null,
            slidesCount: 0,
            isMobile: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.slidesEl = this.$el.querySelectorAll('.home-reference');
        this.slidesCount = this.slidesEl.length;

        this.isMobile = window.matchMedia('(max-width: 767px)').matches;
        this.isTouchDevice = document.querySelector('html').classList.contains('touchevents');

        if (this.isTouchDevice) {
            this.initHammerTime();
        }

        Vue.nextTick(() => {
            this.setSliderInterval();
        });
    },

    beforeDestroy() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        setSliderInterval() {
            this.slideInterval = setInterval(() => {
                this.nextSlide();
            }, 5000);
        },
        stopSliderInterval() {
            clearInterval(this.slideInterval);
        },
        getSlideDirection(e) {
            if (this.isTouchDevice) {
                return;
            }

            const dimensions = window.innerWidth;

            if (e.clientX > dimensions / 2) {
                this.direction = 1;
                this.nextSlide();
                return;
            }

            this.direction = 0;
            this.prevSlide();
        },

        nextSlide() {
            if (this.inTransition) {
                return;
            }

            if (this.currentIndex === this.slidesCount) {
                this.currentIndex = 1;
                return;
            }

            this.currentIndex += 1;
        },

        prevSlide() {
            if (this.inTransition) {
                return;
            }

            if (this.currentIndex === 1) {
                this.currentIndex = this.slidesCount;
                return;
            }

            this.currentIndex -= 1;
        },

        goToSlide(index) {
            this.direction = this.currentIndex > index ? 0 : 1;
            this.currentIndex = index;
        },

        // --------
        // ENTERING
        // --------

        beforeEnter(el) {
            this.inTransition = true;

            const bg = el.querySelector('.home-reference__background');
            const content = el.querySelector('.home-reference__content-holder');
            const title = content.querySelector('.home-reference__title');
            const text = content.querySelector('.home-text');
            const logo = content.querySelector('.home-reference__logo-holder');
            const pagination = el.querySelector('.home-reference__pagination');
            // if (this.direction === 1) {

            if (this.isMobile) {
                gsap.set(bg, {
                    x: '-100%',
                });
                gsap.set(el, {
                    zIndex: 2,

                });
                gsap.set([title, text, pagination], {
                    autoAlpha: 0,
                    x: '100%',
                });
                gsap.set([logo], {
                    autoAlpha: 0,
                    x: '100vw',
                });
            } else {
                gsap.set(bg, {
                    y: '-100%',
                });
                gsap.set(el, {
                    zIndex: 2,

                });
                gsap.set([title, text, logo, pagination], {
                    autoAlpha: 0,
                    y: 200,
                });
            }
        },

        enter(el, done) {
            const tl = gsap.timeline();
            const bg = el.querySelector('.home-reference__background');
            const content = el.querySelector('.home-reference__content-holder');
            const title = content.querySelector('.home-reference__title');
            const text = content.querySelector('.home-text');
            const logo = content.querySelector('.home-reference__logo-holder');
            const pagination = el.querySelector('.home-reference__pagination');

            // if (this.direction === 1) {
            tl.to(bg, {
                duration: 0.6,
                ease: 'power4.inOut',
                y: 0,
                x: 0,
            });
            if (this.isMobile) {
                tl.to([title, text, logo, pagination], {
                    autoAlpha: 1,
                    x: 0,
                    duration: 0.9,
                    ease: 'power3.out',
                    onComplete: done,
                }, '<0.2');
            } else {
                tl.to([title, text, logo, pagination], {
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.9,
                    ease: 'power3.out',
                    onComplete: done,
                }, '-=0.3');
            }
        },

        afterEnter(el) {
            el.classList.add('--visible');
            this.inTransition = false;
        },

        // --------
        // LEAVING
        // --------

        beforeLeave(el) {
            gsap.set(el, {
                zIndex: 1,
            });
        },

        leave(el, done) {
            gsap.delayedCall(1, done);
        },

        afterLeave(el) {
            el.classList.remove('--visible');
            gsap.set(el, {
                zIndex: 0,
            });

            const title = el.querySelector('.home-reference__title');
            title.removeAttribute('data-home-reveal');
        },

        /**
         * Initialize Hammertime
         *
         * @return {void}
         */
        initHammerTime() {
            this.hammertime = new Hammer(this.$el, {});
            this.hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

            this.hammertime.on('swipeleft', () => {
                this.direction = 1;
                this.stopSliderInterval();
                this.nextSlide();
            });

            this.hammertime.on('swiperight', () => {
                this.direction = 0;
                this.stopSliderInterval();
                this.prevSlide();
            });
        },
    },
});
