import Vue from 'vue';

import { gsap, ScrollTrigger } from 'gsap/all';
import imagesLoaded from 'imagesloaded';
import LocomotiveScroll from 'locomotive-scroll';

// eslint-disable-next-line import/no-named-as-default
import SplitText from '../gsap-bonus/SplitText';
import IconLabel from './icon-label.vue';

gsap.registerPlugin(ScrollTrigger);

Vue.component('HorizontalScrollComponent', {
    components: {
        IconLabel,
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            introRevealed: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        imagesLoaded(this.$el, () => {
            Vue.nextTick(() => {
                if (window.matchMedia('(min-width: 768px)').matches) {
                    this.initHorizontalScroll();
                    this.initDesktopRevealTriggers();
                } else {
                    this.initMobileRevealTriggers();
                }
            });
        });
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initHorizontalScroll() {
            const horizontalScroll = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                direction: 'horizontal',
                getSpeed: true,
                tablet: {
                    smooth: true,
                    direction: 'horizontal',
                    horizontalGesture: 'vertical',
                    breakpoint: 767,
                },
            });

            horizontalScroll.on('scroll', (speed) => {
                ScrollTrigger.update();

                if (window.matchMedia('(min-width: 768px)').matches) {
                    EventBus.$emit('horizontal-scroll-event', speed);
                }
            });

            horizontalScroll.on('update', () => {
                ScrollTrigger.update();
            });

            ScrollTrigger.scrollerProxy(document.querySelector('[data-scroll-container]'), {
                scrollLeft(value) {
                    return arguments.length ? horizontalScroll.scrollTo(value, 0, 0) : horizontalScroll.scroll.instance.scroll.x;
                },
                getBoundingClientRect() {
                    return {
                        top: 0, left: 0, width: window.innerWidth, height: window.innerHeight,
                    };
                },
                pinType: document.querySelector('[data-scroll-container]').style.transform ? 'transform' : 'fixed',
            });
        },

        initDesktopRevealTriggers() {
            const revealElements = [
                ...document.querySelectorAll('[data-home-reveal]'),
                ...document.querySelectorAll('[data-home-image-reveal]'),
            ];

            revealElements.forEach((element) => {
                const {
                    revealDelay, desktopRevealDelay, revealManually, revealPosition,
                } = element.dataset;

                if (revealManually === '') {
                    return;
                }

                const startPercentage = revealPosition || '80%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `left ${startPercentage}`,
                        scroller: document.querySelector('[data-scroll-container]'),
                        horizontal: true,
                        onEnter: () => {
                            if (desktopRevealDelay) {
                                if (window.matchMedia('(min-width: 768px)').matches) {
                                    gsap.delayedCall(revealDelay, () => {
                                        element.classList.add('--revealed');

                                        if (!this.introRevealed) {
                                            this.introRevealed = true;
                                        }
                                    });

                                    return;
                                }
                            }

                            if (!revealDelay) {
                                element.classList.add('--revealed');
                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                element.classList.add('--revealed');

                                if (!this.introRevealed) {
                                    this.introRevealed = true;
                                }
                            });
                        },
                    },
                });
            });

            ScrollTrigger.batch('.special-video-component__curtain', {
                start: 'left 95%',
                scroller: document.querySelector('[data-scroll-container]'),
                horizontal: true,
                onEnter: (elements) => {
                    for (let index = 0; index < elements.length; index++) {
                        const element = elements[index];

                        let delay = 0.1 * index;

                        if (!this.introRevealed) {
                            delay = 0.2 + (0.1 * index);
                        }

                        gsap.to(element, {
                            duration: 1.6,
                            scaleY: element.dataset.curtainHeight,
                            delay,
                            ease: 'power4.out',
                        });

                        if (element.hasAttribute('data-reveal-video-play')) {
                            gsap.to('.special-video-component__button', {
                                duration: 1,
                                autoAlpha: 1,
                                delay: 0.6,
                                ease: 'power4.out',
                            });
                        }

                        gsap.fromTo(this.$el.querySelector('.special-video-component__button-text'), { rotation: 0 }, {
                            duration: 18, rotation: 360, repeat: -1, ease: 'none',
                        }, 0);
                    }
                },
            });

            const lineRevealElements = [
                ...document.querySelectorAll('[data-home-line-reveal]'),
            ];

            this.lineReveals = [];

            lineRevealElements.forEach((element) => {
                const mySplitText = new SplitText(element, { type: 'lines', linesClass: 'line-reveal-line' });
                gsap.set(element, { opacity: 1 });

                const linesText = [];

                for (let index = 0; index < mySplitText.lines.length; index++) {
                    const line = mySplitText.lines[index];

                    let inner = line.innerHTML;
                    inner = `<div class="line-reveal-line__text">${inner}</div>`;
                    line.innerHTML = inner;

                    linesText.push(line.querySelector('.line-reveal-line__text'));
                }

                gsap.to(linesText, {
                    scrollTrigger: {
                        trigger: element,
                        start: 'left 80%',
                        once: true,
                        scroller: document.querySelector('[data-scroll-container]'),
                        horizontal: true,
                    },
                    duration: 1,
                    stagger: 0.2,
                    y: '0',
                    ease: 'power4.out',
                    onComplete: () => {
                        this.lineReveals.push(mySplitText);
                    },
                });
            });

            const stickerElements = [
                ...document.querySelectorAll('[data-sticker-reveal]'),
            ];

            stickerElements.forEach((element) => {
                const { revealDelay, revealManually, revealPosition } = element.dataset;

                if (revealManually === '') {
                    return;
                }

                const startPercentage = revealPosition || '80%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `left ${startPercentage}`,
                        once: true,
                        scroller: document.querySelector('[data-scroll-container]'),
                        horizontal: true,
                        onEnter: () => {
                            if (!revealDelay) {
                                const stickerTl = gsap.timeline();

                                stickerTl.to(element.querySelectorAll('.sticker-reveal-background'), { duration: 1, scale: 1, ease: 'power4.out' });
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-text'), { duration: 1, scale: 1, ease: 'power4.out' }, 0);
                                stickerTl.fromTo(element.querySelectorAll('.sticker-reveal-text'), { rotation: 0 }, {
                                    duration: 18, rotation: 360, repeat: -1, ease: 'none',
                                }, 0);
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-icon'), { duration: 1, autoAlpha: 1, ease: 'power4.out' }, 0.5);

                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                const stickerTl = gsap.timeline();

                                stickerTl.to(element.querySelectorAll('.sticker-reveal-background'), { duration: 1, scale: 1, ease: 'power4.out' });
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-text'), { duration: 1, scale: 1, ease: 'power4.out' }, 0);
                                stickerTl.fromTo(element.querySelectorAll('.sticker-reveal-text'), { rotation: 0 }, {
                                    duration: 18, rotation: 360, repeat: -1, ease: 'none',
                                }, 0);
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-icon'), { duration: 1, autoAlpha: 1, ease: 'power4.out' }, 0.5);
                            });

                            gsap.fromTo(this.$el.querySelector('.special-video-component__button-text'), { rotation: 0 }, {
                                duration: 18, rotation: 360, repeat: -1, ease: 'none',
                            }, 0);
                        },
                    },
                });
            });

            gsap.to('.whatsapp-cta', {
                duration: 1,
                scale: 1,
                ease: 'power4.out',
                delay: 0.8,
                onComplete: () => {
                    document.querySelector('.whatsapp-cta').classList.add('revealed');
                    gsap.set('.whatsapp-cta', { clearProps: 'all' });
                },
            });

            gsap.fromTo('.whatsapp-cta__text', { rotation: 0 }, {
                duration: 20, rotation: 360, repeat: -1, ease: 'none',
            });

            gsap.set(this.$el.querySelector('.home-slider__images-holder.--visible img'), { scale: 1.2 });

            gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el.querySelector('.home-slider'),
                    start: 'left 90%',
                    scroller: document.querySelector('[data-scroll-container]'),
                    horizontal: true,
                    onEnter: () => {
                        gsap.to(this.$el.querySelector('.home-slider__images-holder.--visible img'), {
                            duration: 2,
                            scale: 1,
                            ease: 'power4.out',
                            onComplete: () => {
                                gsap.set(this.$el.querySelector('.home-slider__images-holder.--visible img'), { clearProps: 'all' });
                            },
                        });
                    },
                },
            });

            const teamRevealElements = [
                ...document.querySelectorAll('[data-horizontal-team-reveal]'),
            ];

            teamRevealElements.forEach((element) => {
                gsap.to(element, {
                    scrollTrigger: {
                        trigger: element,
                        start: 'left 100%',
                        once: true,
                        scroller: document.querySelector('[data-scroll-container]'),
                        horizontal: true,
                    },
                    duration: 1.6,
                    scaleY: 0,
                    ease: 'power4.out',
                });
            });

            gsap.to('.horizontal-scroll-indicator', {
                scrollTrigger: {
                    trigger: document.querySelector('.horizontal-scroll'),
                    start: 'left left',
                    end: 'right right',
                    scrub: true,
                    scroller: document.querySelector('[data-scroll-container]'),
                    horizontal: true,
                },
                scaleX: 1,
                ease: 'none',
            });
        },

        initMobileRevealTriggers() {
            const revealElements = [
                ...document.querySelectorAll('[data-home-reveal]'),
                ...document.querySelectorAll('[data-home-image-reveal]'),
                ...document.querySelectorAll('[data-home-mobile-image-reveal]'),
            ];

            revealElements.forEach((element) => {
                const { revealDelay, revealManually, revealPosition } = element.dataset;

                if (revealManually === '') {
                    return;
                }

                const startPercentage = revealPosition || '90%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `top ${startPercentage}`,
                        onEnter: () => {
                            if (!revealDelay) {
                                element.classList.add('--revealed');
                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                element.classList.add('--revealed');

                                if (!this.introRevealed) {
                                    this.introRevealed = true;
                                }
                            });
                        },
                    },
                });
            });

            const stickerElements = [
                ...document.querySelectorAll('[data-sticker-reveal]'),
            ];

            stickerElements.forEach((element) => {
                const { revealDelay, revealManually, revealPosition } = element.dataset;

                if (revealManually === '') {
                    return;
                }

                const startPercentage = revealPosition || '90%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `top ${startPercentage}`,
                        once: true,
                        onEnter: () => {
                            if (!revealDelay) {
                                const stickerTl = gsap.timeline();

                                stickerTl.to(element.querySelectorAll('.sticker-reveal-background'), { duration: 1, scale: 1, ease: 'power4.out' });
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-text'), { duration: 1, scale: 1, ease: 'power4.out' }, 0);
                                stickerTl.fromTo(element.querySelectorAll('.sticker-reveal-text'), { rotation: 0 }, {
                                    duration: 18, rotation: 360, repeat: -1, ease: 'none',
                                }, 0);
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-icon'), { duration: 1, autoAlpha: 1, ease: 'power4.out' }, 0.5);

                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                const stickerTl = gsap.timeline();

                                stickerTl.to(element.querySelectorAll('.sticker-reveal-background'), { duration: 1, scale: 1, ease: 'power4.out' });
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-text'), { duration: 1, scale: 1, ease: 'power4.out' }, 0);
                                stickerTl.fromTo(element.querySelectorAll('.sticker-reveal-text'), { rotation: 0 }, {
                                    duration: 18, rotation: 360, repeat: -1, ease: 'none',
                                }, 0);
                                stickerTl.to(element.querySelectorAll('.sticker-reveal-icon'), { duration: 1, autoAlpha: 1, ease: 'power4.out' }, 0.5);
                            });
                        },
                    },
                });
            });

            const lineRevealElements = [
                ...document.querySelectorAll('[data-home-line-reveal]'),
            ];

            this.lineReveals = [];

            lineRevealElements.forEach((element) => {
                const mySplitText = new SplitText(element, { type: 'lines', linesClass: 'line-reveal-line' });
                gsap.set(element, { opacity: 1 });

                const linesText = [];

                for (let index = 0; index < mySplitText.lines.length; index++) {
                    const line = mySplitText.lines[index];

                    let inner = line.innerHTML;
                    inner = `<div class="line-reveal-line__text">${inner}</div>`;
                    line.innerHTML = inner;

                    linesText.push(line.querySelector('.line-reveal-line__text'));
                }

                gsap.to(linesText, {
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 90%',
                        once: true,
                    },
                    duration: 1,
                    stagger: 0.2,
                    y: '0',
                    ease: 'power4.out',
                    onComplete: () => {
                        this.lineReveals.push(mySplitText);
                    },
                });
            });

            ScrollTrigger.batch('.special-video-component__curtain', {
                start: 'top 90%',
                onEnter: (elements) => {
                    for (let index = 0; index < elements.length; index++) {
                        const element = elements[index];

                        let delay = 0.1 * index;

                        if (!this.introRevealed) {
                            delay = 0.2 + (0.1 * index);
                        }

                        gsap.to(element, {
                            duration: 3,
                            scaleY: element.dataset.curtainHeight,
                            delay,
                            ease: 'power4.out',
                        });

                        if (element.hasAttribute('data-reveal-video-play')) {
                            gsap.to('.special-video-component__button', {
                                duration: 1,
                                autoAlpha: 1,
                                delay: 0.6,
                                ease: 'power4.out',
                            });
                        }
                    }
                },
            });

            const teamRevealElements = [
                ...document.querySelectorAll('[data-mobile-team-reveal]'),
            ];

            teamRevealElements.forEach((element) => {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 90%',
                        onEnter: () => {
                            element.classList.add('--revealed');
                        },
                    },
                });
            });

            gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el.querySelector('.home-slider'),
                    start: 'top 90%',
                    onEnter: () => {
                        gsap.to(this.$el.querySelector('.home-slider__images'), {
                            duration: 1.6,
                            autoAlpha: 1,
                            y: '0%',
                            ease: 'power4.out',
                        });
                    },
                },
            });

            gsap.to('.whatsapp-cta', {
                duration: 0.8, x: '0%', y: '0%', ease: 'power4.out', delay: 1.4,
            });
        },
    },
});
