<template>
    <div class="related-container">
        <VacancyCard v-for="item in vacancies" :key="item.id" :item="item"/>
    </div>
</template>

<script>
import VacancyCard from './vacancy-list/vacancy-card.vue';

export default {
    components: {
        VacancyCard
    },
    props: {
        vacancies: {
            type: Array,
            required: true
        }
    },
};
</script>