<template>
    <div class="vacancy-filters">
        <h3 class="text-body-title">Filters</h3>
        <div class="vacancy-filters__content">
            <div class="vacancy-filters__group">
                <span>Beroepsgroepen</span>
                <div class="vacancy-filters__group-checkboxes">
                    <div
                        v-for="(sector, index) in orderedSectors.slice(0, sectorsCount)"
                        :key="sector"
                        class="checkbox-holder"
                    >
                        <input
                            :id="sector"
                            v-model="checkedSectors"
                            type="checkbox"
                            :name="sector"
                            class="checkbox"
                            :value="sector"
                            @change.prevent="onSelect"
                        />
                        <label :for="sector" class="checkbox-label">
                            <svg
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 4.5L4.5 8L11.5 1"
                                    stroke="white"
                                    stroke-width="2"
                                />
                            </svg>
                            {{ sector }}</label
                        >
                    </div>
                </div>
                <div class="vacancy-filters__group-checkboxes vacancy-filters__group-checkboxes--show-more">
                    <div
                        v-for="(sector, index) in orderedSectors.slice(sectorsCount, orderedSectors.length)"
                        :key="sector"
                        class="checkbox-holder"
                    >
                        <input
                            :id="sector"
                            v-model="checkedSectors"
                            type="checkbox"
                            :name="sector"
                            class="checkbox"
                            :value="sector"
                            @change.prevent="onSelect"
                        />
                        <label :for="sector" class="checkbox-label">
                            <svg
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 4.5L4.5 8L11.5 1"
                                    stroke="white"
                                    stroke-width="2"
                                />
                            </svg>
                            {{ sector }}</label
                        >
                    </div>
                </div>
                <div class="button vacancy-filters__button" @click.prevent="showAllSectors" v-if="sectors.length > sectorsCount && !sectorsShowAll">{{ translations ? translations['show-more'] : 'Show More'}}</div>
                <div class="button vacancy-filters__button" @click.prevent="showLessSectors" v-else-if="sectorsShowAll">{{ translations ? translations['show-less'] : 'Show Less'}}</div>
            </div>
            <div class="vacancy-filters__group">
                <span>Opleidingsniveau</span>
                <div class="vacancy-filters__group-checkboxes">
                    <div
                        v-for="level in orderedEducationLevels"
                        :key="level"
                        class="checkbox-holder"
                    >
                        <input
                            :id="level"
                            v-model="checkedEducationLevels"
                            type="checkbox"
                            :name="level"
                            class="checkbox"
                            :value="level"
                            @change.prevent="onSelect"
                        />
                        <label :for="level" class="checkbox-label">
                            <svg
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 4.5L4.5 8L11.5 1"
                                    stroke="white"
                                    stroke-width="2"
                                />
                            </svg>
                            {{ level }}</label
                        >
                    </div>
                </div>
            </div>
            <div class="vacancy-filters__group">
                <span>Dienstverband</span>
                <div class="vacancy-filters__group-checkboxes">
                    <div
                        v-for="value in employmentTypes"
                        :key="value"
                        class="checkbox-holder"
                    >
                        <input
                            :id="value"
                            v-model="checkedEmploymentTypes"
                            type="checkbox"
                            :name="value"
                            class="checkbox"
                            :value="value"
                            @change.prevent="onSelect"
                        />
                        <label :for="value" class="checkbox-label">
                            <svg
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 4.5L4.5 8L11.5 1"
                                    stroke="white"
                                    stroke-width="2"
                                />
                            </svg>
                            {{ value }}</label
                        >
                    </div>
                </div>
            </div>
            <div class="vacancy-filters__group">
                <span>Regio</span>
                <div class="vacancy-filters__group-checkboxes">
                    <div
                        v-for="value in regions"
                        :key="value"
                        class="checkbox-holder"
                    >
                        <input
                            :id="value"
                            v-model="checkedRegions"
                            type="checkbox"
                            :name="value"
                            class="checkbox"
                            :value="value"
                            @change.prevent="onSelect"
                        />
                        <label :for="value" class="checkbox-label">
                            <svg
                                width="13"
                                height="10"
                                viewBox="0 0 13 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 4.5L4.5 8L11.5 1"
                                    stroke="white"
                                    stroke-width="2"
                                />
                            </svg>
                            {{ value }}</label
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import gsap from 'gsap';
export default {
    name: 'VacancyFilters',
    props: {
        sectors: {
            type: Array,
            required: true,
        },
        educationLevels: {
            type: Array,
            required: true,
        },
        employmentTypes: {
            type: Array,
            required: true,
        },
        regions: {
            type: Array,
            required: true,
        },
        activeFilters: {
            type: Object,
            required: true,
        },
        translations: {
            type: Object,
            required: false,
        },
    },
    watch: {
        activeFilters: {
            handler: function (after, before) {
                this.checkedSectors = after.sectors;
                this.checkedEmploymentTypes = after.employmentTypes;
                this.checkedEducationLevels = after.educationLevels;
                this.checkedRegions = after.regions;
            },
            deep: true
        },
    },
    data() {
        return {
            checkedSectors: [],
            checkedEmploymentTypes: [],
            checkedEducationLevels: [],
            checkedRegions: [],
            sectorsCount: 4,
            sectorsShowAll: false,
            sectorsEndHeight: 0,
        };
    },
    computed: {
        orderedSectors: function() {
            return  this.sectors.sort() //.slice(0, this.sectorsCount);
        },

        orderedEducationLevels: function () {
            const definedOrder = ['Ongeschoold', 'Vmbo', 'Mavo', 'Havo', 'Vwo', 'Mbo', 'Hbo', 'Wo'];

            return this.educationLevels.sort((a, b) => {
                return definedOrder.indexOf(a) - definedOrder.indexOf(b)
            });
        },
    },
    mounted() {
            console.log('orderedSectors');
            // let element = document.querySelector('.vacancy-filters__group-checkboxes--show-more');
            // this.sectorsEndHeight = element.offsetHeight;
            // console.log(element, this.sectorsEndHeight);
            // // Set the height back to the start height
            // element.style.height = 0;
    },
    methods: {
        showAllSectors() {
            this.sectorsShowAll = true;
            // Get the element you want to animate
            let element = this.$el.querySelector('.vacancy-filters__group-checkboxes--show-more');

            // Now you can animate the height
            gsap.to(element, {height: 'auto', duration: 1});
        },
        showLessSectors() {
            this.sectorsShowAll = false;
            let element = this.$el.querySelector('.vacancy-filters__group-checkboxes--show-more');
            gsap.to(element, {height: 0, duration: 1});
        },
        onSelect() {
            this.$emit('changeFilters', {
                sectors: this.checkedSectors,
                employmentTypes: this.checkedEmploymentTypes,
                educationLevels: this.checkedEducationLevels,
                regions: this.checkedRegions,
            });
        },
    },
};
</script>
