/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'lazysizes';
import { gsap, ScrollTrigger } from 'gsap/all';
import { debounce } from 'lodash';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Rellax from 'rellax';
import Vue from 'vue';
import Headroom from 'headroom.js';
import FontFaceObserver from 'fontfaceobserver';
import Router from './router';

// eslint-disable-next-line import/no-named-as-default
import SplitText from './gsap-bonus/SplitText';

import VacancyList from './components/vacancy-list/index.vue';
import VacancyCard from './components/vacancy-list/vacancy-card.vue';
import VacancyForm from './components/vacancy-form.vue';
import VacancyRow from './components/vacancy-row.vue';
import OpenRegistrationForm from './components/open-registration-form.vue';

require('./bootstrap');
require('./components/search-form');
require('./components/search-form-footer');
require('./components/video-component');
require('./components/slider-component');
require('./components/ticker-component');
require('./components/vertical-ticker-component');
require('./components/headroom-component');
require('./components/horizontal-scroll-component');
require('./components/special-video-component');
require('./components/googlemap-component');
require('./components/home-slider-component');
require('./components/overlay-video-component');
require('./components/faq-component');
require('./components/info-section');
require('./components/references-slider-component');
require('./components/sticker-rotation-component');
require('./components/cv-upload');

gsap.registerPlugin(ScrollTrigger);

Vue.config.ignoredElements = [
    'recruitnow-open-enrollment-widget',
    'jobboard-application-form',
    'recruitnow-cv-generator',
];

const App = new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    components: {
        VacancyList,
        VacancyForm,
        OpenRegistrationForm,
        VacancyCard,
        VacancyRow,
    },

    data() {
        return {
            router: {
                currentSection: null,
                instance: null,
                prefetch: true,
            },
            // router: null,
            // isMobile: false,
            // isTablet: false,
            // scrollbar: null,
            showNavigation: false,
            inTransition: false,
            // darkHeader: '1',
            // showFooter: '1'
        };
    },

    watch: {
        isMobile(value) {
            EventBus.$emit('is-mobile-changed');
        },
        isTablet(value) {
            EventBus.$emit('is-tablet-changed');
        },
        showNavigation(visibile) {
            EventBus.$emit('navigation-state-changed', visibile);
        },
    },

    mounted() {
        window.addEventListener('resize', this.onWindowResize);
        gsap.set('.navigation-overlay', { clip: `rect(0px, 0px, ${window.innerHeight}px, 0px)` });

        const fontA = new FontFaceObserver('Gotham A');
        const fontB = new FontFaceObserver('Gotham B');

        Promise.all([fontA.load(null, 500), fontB.load(null, 500)]).then(() => {
            Vue.nextTick(() => {
                // console.log('Fonts are loaded.');
                this.initRevealTriggers();
            });
        }).catch(() => {
            Vue.nextTick(() => {
                // console.log('Catch fonts load promise.');
                this.initRevealTriggers();
            });
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    },

    methods: {
        toggleMenu() {
            if (this.inTransition === false) {
                if (this.showNavigation === true) {
                    this.closeMenu();
                } else {
                    this.openMenu();
                }
            }
        },

        closeMenu() {
            this.inTransition = true;
            const onReverseComplete = () => {
                enableBodyScroll(document.querySelector('#navigation-overlay'));

                this.inTransition = false;
                this.showNavigation = false;
            };

            // close for mobile
            if (window.matchMedia('(max-width: 1023px)').matches) {
                gsap.to('.navigation__divider', {
                    delay: 0.3, duration: 0.3, scaleX: 0, ease: 'power3.out',
                });
                gsap.to('.navigation__info-block', {
                    delay: 0.3, duration: 1, autoAlpha: 0, ease: 'power3.out', stagger: 0.1,
                });
                gsap.to('.navigation__info-social', {
                    delay: 1, duration: 1, autoAlpha: 0, ease: 'power3.out',
                });
                gsap.to('.navigation__footer', {
                    delay: 1, duration: 1, autoAlpha: 0, ease: 'power3.out',
                });
                gsap.to('.navigation-overlay', {
                    delay: 0.3, duration: 1, clip: `rect(0px, 0px, ${window.innerHeight}px, 0px)`, ease: 'power3.out',
                });
                gsap.to('.navigation-menu__link', {
                    delay: 0.3, duration: 1, autoAlpha: 0, y: '100%', ease: 'power3.out', stagger: 0.1, onComplete: onReverseComplete,
                });
            }

            // close for desktop
            gsap.set('.navigation__divider', { scaleY: 0 });

            gsap.to('.navigation__divider', {
                delay: 0.3, duration: 1.5, scaleY: 0, ease: 'power3.out',
            });
            gsap.to('.navigation__info-block', {
                delay: 0.3, duration: 1.5, autoAlpha: 0, ease: 'power3.out', stagger: 0.15,
            });
            gsap.to('.navigation__info-social', {
                delay: 1, duration: 1.8, autoAlpha: 0, ease: 'power3.out',
            });
            gsap.to('.navigation__footer', {
                delay: 1, duration: 1, autoAlpha: 0, ease: 'power3.out',
            });
            gsap.to('.navigation-overlay', {
                delay: 0.3, duration: 1, clip: `rect(0px, 0px, ${window.innerHeight}px, 0px)`, ease: 'power3.out',
            });
            gsap.to('.navigation-menu__link', {
                delay: 0.3, duration: 1, autoAlpha: 0, y: '100%', ease: 'power3.out', stagger: 0.1, onComplete: onReverseComplete,
            });
        },

        openMenu() {
            this.showNavigation = true;
            this.inTransition = true;
            const onComplete = () => {
                this.inTransition = false;
            };

            disableBodyScroll(document.querySelector('#navigation-overlay'), { reserveScrollBarGap: true });

            gsap.killTweensOf('.navigation-menu__link');
            gsap.set(['.navigation-menu__link', '.navigation__info-item'], { autoAlpha: 0, y: '100%' });
            gsap.set('.navigation__footer', { autoAlpha: 0 });

            // open for mobile
            if (window.matchMedia('(max-width: 1023px)').matches) {
                gsap.set('.navigation__divider', { scaleX: 0 });

                gsap.to('.navigation-overlay', { duration: 1, clip: `rect(0px, ${window.innerWidth}px, ${window.innerHeight}px, 0px)`, ease: 'power3.out' });
                gsap.to('.navigation__divider', {
                    delay: 0.3, duration: 0.3, scaleX: 1, ease: 'power3.out',
                });
                gsap.to('.navigation__footer', {
                    delay: 1, duration: 1, autoAlpha: 1, ease: 'power3.out',
                });
                gsap.to(['.navigation-menu__link', '.navigation__info-item'], {
                    delay: 0.3, duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out', stagger: 0.1, onComplete,
                });
            }

            // open for desktop
            gsap.set('.navigation__divider', { scaleY: 0 });

            gsap.to('.navigation-overlay', { duration: 1, clip: `rect(0px, ${window.innerWidth}px, ${window.innerHeight}px, 0px)`, ease: 'power3.out' });
            gsap.to('.navigation__divider', {
                delay: 0.3, duration: 1.5, scaleY: 1, ease: 'power3.out',
            });
            gsap.to('.navigation__footer', {
                delay: 1, duration: 1, autoAlpha: 1, ease: 'power3.out',
            });
            gsap.to(['.navigation-menu__link', '.navigation__info-item'], {
                delay: 0.3, duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out', stagger: 0.1, onComplete,
            });
        },

        initRevealTriggers() {
            const elements = [
                ...document.querySelectorAll('[data-fade-reveal]'),
                ...document.querySelectorAll('[data-image-reveal]'),
                ...document.querySelectorAll('[data-video-reveal]'),
            ];

            elements.forEach((element) => {
                const { revealDelay, revealManually, revealPosition } = element.dataset;

                if (revealManually === '') {
                    return;
                }

                const startPercentage = revealPosition || '80%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `top ${startPercentage}`,
                        onEnter: () => {
                            if (!revealDelay) {
                                element.classList.add('--revealed');
                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                element.classList.add('--revealed');
                            });
                        },
                        markers: process.env === 'dev',
                    },
                });
            });

            const lineRevealElements = [
                ...document.querySelectorAll('[data-line-reveal]'),
            ];

            this.lineReveals = [];

            lineRevealElements.forEach((element) => {
                const mySplitText = new SplitText(element, { type: 'lines', linesClass: 'line-reveal-line' });
                gsap.set(element, { opacity: 1 });

                const linesText = [];

                for (let index = 0; index < mySplitText.lines.length; index++) {
                    const line = mySplitText.lines[index];

                    let inner = line.innerHTML;
                    inner = `<div class="line-reveal-line__text">${inner}</div>`;
                    line.innerHTML = inner;

                    linesText.push(line.querySelector('.line-reveal-line__text'));
                }

                gsap.to(linesText, {
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                        markers: process.env === 'dev',
                    },
                    duration: 1,
                    stagger: 0.2,
                    y: '0',
                    ease: 'power4.out',

                    onComplete: () => {
                        this.lineReveals.push(mySplitText);
                    },
                });
            });

            const teamElements = [
                ...document.querySelectorAll('[data-team-reveal]'),
            ];

            teamElements.forEach((element) => {
                const { revealDelay, revealPosition } = element.dataset;

                const startPercentage = revealPosition || '80%';

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `top ${startPercentage}`,
                        onEnter: () => {
                            if (!revealDelay) {
                                const teamTl = gsap.timeline();

                                teamTl.to('.team-member__inner', {
                                    duration: 0.5, stagger: 0.3, opacity: 1, ease: 'linear',
                                });
                            }
                        },
                    },
                });
            });
        },

        /**
         * Raised when the client window is resizing.
         *
         * @return {Void}
         */
        onWindowResize: debounce(() => {
            EventBus.$emit('window-resize');
        }, 150),

        detectMobile() {
            this.isMobile = window.innerWidth <= 768;
            this.isTablet = window.innerWidth <= 1024;
            console.log(`Is Mobile: ${this.isMobile}`);
        },
    },
});

export default App;
