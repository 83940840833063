import Vue from 'vue';

export default Vue.extend({
    name: 'PageComponent',
    delimiters: ['${', '}'],

    components: {
        // 'homepage-hero': require('./../../components/homepage-hero')
    },

    mounted() {
        Vue.nextTick(() => {
            window.requestAnimationFrame(() => {
                EventBus.$emit('page-transition-enter');
            });
        });
    },

    methods: {
        toggleShowreel() {},
    },
});
