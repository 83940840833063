/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { gsap, ScrollTrigger } from 'gsap/all';
import Hammer from 'hammerjs';
import imagesLoaded from 'imagesloaded';

gsap.registerPlugin(ScrollTrigger);

Vue.component('HomeSliderComponent', {
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentIndex: 1,
            direction: 0,
            inTransition: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (window.matchMedia('(max-width: 767px)').matches) {
            imagesLoaded(this.$el, () => {
                Vue.nextTick(() => {
                    const slides = document.querySelectorAll('.home-slider__content-item');
                    const slideHeights = [];

                    slides.forEach((element) => {
                        element.style.display = 'block';
                        slideHeights.push(element.getBoundingClientRect().height);

                        if (!element.classList.contains('--visible')) {
                            element.style.display = 'none';
                        }
                    });

                    const highestSlide = Math.max(...slideHeights);
                    const padding = window.innerWidth * 0.2222;
                    const slideContentHeight = Math.ceil(highestSlide + padding);

                    document.querySelector('.home-slider__content').style.minHeight = `${slideContentHeight}px`;
                });
            });
        }

        this.isTouchDevice = document.querySelector('html').classList.contains('touchevents');

        this.$el.querySelector('.home-slider__images-holder').classList.add('--visible');
        this.$el.querySelector('.home-slider__content-item').classList.add('--visible');

        if (this.isTouchDevice) {
            this.initHammerTime();
        }
    },

    beforeDestroy() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        getSlideDirection(e) {
            if (this.isTouchDevice) {
                return;
            }

            const dimensions = window.innerWidth;

            if (e.clientX > dimensions / 2) {
                this.direction = 1;
                this.nextSlide();
                return;
            }

            this.direction = 0;
            this.prevSlide();
        },

        nextSlide() {
            if (this.inTransition) {
                return;
            }

            if (this.currentIndex === this.slides.length) {
                this.currentIndex = 1;
                return;
            }

            this.currentIndex += 1;
        },

        prevSlide() {
            if (this.inTransition) {
                return;
            }

            if (this.currentIndex === 1) {
                this.currentIndex = this.slides.length;
                return;
            }

            this.currentIndex -= 1;
        },

        goToSlide(index) {
            this.direction = this.currentIndex > index ? 0 : 1;
            this.currentIndex = index;
        },

        // --------
        // ENTERING
        // --------

        beforeEnter(el) {
            this.inTransition = true;
            el.style.zIndex = 2;

            const img = el.querySelector('.home-slider__images-image');
            const box = this.$el.querySelector('.home-slider__images-holder.--visible').getBoundingClientRect();

            img.classList.add('--js-transition');
            if (this.direction === 1) {
                gsap.set(el, {
                    clip: `rect(0px, ${box.width}px, ${box.height}px, ${box.height}px)`,
                });
            } else {
                gsap.set(el, {
                    clip: `rect(0px, 0px, ${box.height}px, 0px)`,
                });
            }

            gsap.set(img, {
                scale: 1.4,
                xPercent: () => (this.direction === 1 ? 50 : -50),
            });
        },

        enter(el, done) {
            const tl = gsap.timeline();
            const img = el.querySelector('.home-slider__images-image');
            const box = this.$el.querySelector('.home-slider__images-holder.--visible').getBoundingClientRect();

            if (this.direction === 1) {
                gsap.to(el, {
                    duration: 1.2,
                    ease: 'power4.out',
                    clip: `rect(0px, ${box.width}px, ${box.height}px, 0px)`,
                });
            } else {
                gsap.to(el, {
                    duration: 1.2,
                    ease: 'power4.out',
                    clip: `rect(0px, ${box.width}px, ${box.height}px, 0px)`,
                });
            }

            tl
            .to(img, 1.5, {
                scale: 1,
                xPercent: 0,
                ease: 'power4.out',
                onComplete: done,
            });
        },

        afterEnter(el) {
            el.style.zIndex = '';
            el.style.clip = '';
            el.classList.add('--visible');
            this.inTransition = false;
        },

        enterContent(el, done) {
            gsap.fromTo(el, { x: -20, autoAlpha: 0 }, {
                duration: 1.4, delay: 0.4, x: 0, autoAlpha: 1, ease: 'power4.out', onComplete: done,
            });
        },

        afterEnterContent(el) {
            el.classList.add('--visible');
        },

        // --------
        // LEAVING
        // --------

        beforeLeave(el) {
            const img = el.querySelector('.home-slider__images-image');

            img.classList.add('--js-transition');
            gsap.set(img, {
                scale: 1,
                xPercent: 0,
            });
        },

        leave(el, done) {
            const tl = gsap.timeline();
            const img = el.querySelector('.home-slider__images-image');

            tl
            .to(img, 1.5, {
                scale: 1.4,
                xPercent: () => (this.direction === 1 ? -25 : 25),
                ease: 'power4.out',
                onComplete: done,
            });
        },

        afterLeave(el) {
            el.classList.remove('--visible');
        },

        leaveContent(el, done) {
            gsap.to(el, {
                duration: 1, x: 20, autoAlpha: 0, ease: 'power4.out', onComplete: done,
            });
        },

        afterLeaveContent(el) {
            el.classList.remove('--visible');
        },

        /**
         * Initialize Hammertime
         *
         * @return {void}
         */
        initHammerTime() {
            this.hammertime = new Hammer(this.$el, {});
            this.hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });

            this.hammertime.on('swipeleft', () => {
                this.direction = 1;
                this.nextSlide();
            });

            this.hammertime.on('swiperight', () => {
                this.direction = 0;
                this.prevSlide();
            });
        },
    },
});
