import Vue from 'vue';
import Plyr from 'plyr';

Vue.component('OverlayVideoComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            showOverlay: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.video = new Plyr(this.$refs.video, {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            youtube: {
                noCookie: true,
            },
        });

        this.video.on('play', () => {
            this.showOverlay = true;
        });

        // this.video.on('pause', () => {
        //     this.showOverlay = false;
        // });

        this.video.on('seeking', () => {
            this.showOverlay = true;
        });

        this.video.on('ended', () => {
            this.showOverlay = false;
        });

        EventBus.$on('play-overlay-video', this.playVideo);
    },

    methods: {
        playVideo() {
            this.showOverlay = true;
            this.video.play();
        },

        closeOverlay() {
            this.showOverlay = false;
            this.video.pause();
        },
    },
});
