import Vue from 'vue';
import Headroom from 'headroom.js';

Vue.component('HeadroomComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            showBar: false,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        const headroomElement = document.getElementById('headroom');
        const headroom = new Headroom(headroomElement, {
            offset: 150,
        });
        headroom.init();
    },

    methods: {
        toggleBar() {
            this.showBar = !this.showBar;
        },
    },
});
