import Vue from 'vue';

Vue.component('SearchForm', {
    data() {
        return {
            queryPostalcode: '',
            queryFunctie: '',
            errors: [],
        };
    },
    methods: {
        onSubmit() {
            this.errors = [];

            // if (!this.queryPostalcode && !this.queryFunctie) {
            //     this.errors.push('Dit veld is verplicht');
            //     return;
            // }

            // const match = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(
            //     this.queryPostalcode,
            // );

            if (this.queryPostalcode && this.queryFunctie) {
                window.location = `/vacatures?postcode=${this.queryPostalcode}&zoeken=${this.queryFunctie}`;
            } else if (this.queryPostalcode) {
                window.location = `/vacatures?postcode=${this.queryPostalcode}`;
            } else if (this.queryFunctie) {
                window.location = `/vacatures?zoeken=${this.queryFunctie}`;
            } else {
                window.location = '/vacatures';
            }
        },
    },
});
