import Vue from 'vue';
import { gsap } from 'gsap';

Vue.component('TickerComponent', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            tickerContainer: null,
            tickerText: null,
            tickerWidth: null,
            tickerTimeline: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        EventBus.$on('window-resize', () => {
            this.destroyTicker(true);
        });
        // The delayed call is needed because the font is still loading when initializing this.
        gsap.delayedCall(0.4, this.initTicker.bind(this));
    },

    beforeDestroy() {
        this.destroyTicker();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initTicker() {
            // Source: https://codepen.io/GreenSock/pen/QEdpLe
            this.tickerContainer = this.$el.querySelector('.ticker__container');
            this.tickerText = this.$el.querySelectorAll('.ticker__text');
            this.tickerWidth = this.tickerText[0].getBoundingClientRect().width;
            this.tickerTimeline = gsap.timeline();

            this.tickerTimeline.to(this.tickerContainer, {
                duration: 21,
                ease: 'none',
                x: -this.tickerWidth, // move each box 500px to right
                modifiers: {
                    x: gsap.utils.unitize(
                        (x) => parseFloat(x) % this.tickerWidth,
                    ), // force x value to be between 0 and 500 using modulus
                },
                repeat: -1,
            });
        },

        destroyTicker(reinit = false) {
            if (!this.tickerTimeline) {
                return;
            }

            this.tickerTimeline.kill();

            if (!reinit) {
                return;
            }

            gsap.set(this.tickerContainer, {
                x: 0,
            });
            this.initTicker();
        },
    },
});
