import Vue from 'vue';
import { gsap } from 'gsap';

Vue.component('faq-component', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        toggleFaq(event) {
            const faqBlock = event.currentTarget.parentElement;
            const faqAnswer = event.currentTarget.nextElementSibling;

            if (faqBlock.classList.contains('active')) {
                faqBlock.classList.remove('active');
                gsap.to(faqAnswer, 0.3, {
                    height: 0,
                    ease: 'Quad.easeOut',
                });
            } else {
                faqBlock.classList.add('active');
                gsap.to(faqAnswer, 0.3, {
                    height: 'auto',
                    ease: 'power4.out',
                });
            }
        },
    },
});
